<template>
  <el-container>
    <el-main style="margin:0;padding:0;">
      <el-container class="main-container" :class="{'pc':!isMobile(),'m':isMobile()}">
        <el-header class="navbarBox" height="208">
			<div class="tabnav maxBox">
				<div class="logoImg" style="height: 88px;width: 105px;margin-top: 36px;"><router-link to="/"><img src="@/assets/images/logo-xianling.png" /></router-link></div>
				<div class="tabBtn">
					<span class="menuBtn" v-if="!showMenu" @click="showMenu = true">
						<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
						  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
						</svg>
					</span>
					<span class="closeBtn" v-else @click="showMenu = false">
						<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
						</svg>
					</span>
				</div>
				<div class="tablist" v-if="showMenu || !isMobile()">
					<ul>
						<li :class="{'active':current=='/index'}">
							<router-link to="/index">首页</router-link>
						</li>
						<!-- <li :class="{'active':current=='/introduce'}">
							<router-link to="/introduce">走进奇葩</router-link>
						</li>
						<li :class="{'active':current=='/product'}">
							<router-link to="/product">联研产品</router-link>
						</li> -->
						<li :class="{'active':current=='/culture'}">
							<router-link to="/culture">公司文化</router-link>
						</li>
						<li :class="{'active':current=='/joinus'}">
							<router-link to="/joinus">加入我们</router-link>
						</li>
						<li :class="{'active':current=='/contact'}">
							<router-link to="/contact">联系我们</router-link>
						</li>
					</ul>
				</div>
			</div>
		</el-header>
        <el-main>
          <AppMain />
		  <el-footer class="footerBox" :class="{'pc':!isMobile(),'m':isMobile()}">
		  	<div class="maxBox">
		  		<div class="leftLogo">
		  			<!-- <img src="@/assets/logo.png" /> -->
					<!-- <p><a href="https://www.beian.gov.cn/portal/registerSystemInfo" target="_blank" style="color: #fff;">
						<span style="white-space:nowrap;">公安备案号：43019002000526</span>
					</a></p> -->
		  			<p><a href="https://beian.miit.gov.cn" target="_blank"><span style="white-space:nowrap;">粤ICP备20026950号 Copyright© 2023</span></a></p>
		  			<p>广州仙灵网络科技有限公司 版权所有</p>
		  			<p>联系人：{{ businessName }}</p>
		  			<p>合作邮箱：{{ businessEmail }}</p>
		  			<!-- <p>联系地址：{{ address }}</p> -->
		  			<p>联系地址：广州市天河区荷光路盛达商务园D座505</p>
		  		</div>
		  		<div class="qrcodeBox">
		  			<div class="qrcode">
		  				<img src="@/assets/images/qrcode.jpg" />
		  			</div>
		  		</div>
		  	</div>
		  </el-footer>
        </el-main>
        
      </el-container>
    </el-main>
  </el-container>
</template>

<script setup>
import { isMobile } from '@/utils/common.js'
import { ref,defineAsyncComponent,onMounted,getCurrentInstance } from 'vue'
import { useRoute,useRouter,onBeforeRouteUpdate } from 'vue-router'
import useUserStore from '@/store/modules/user'
import { getPartDetail } from '@/api/index'

const AppMain = defineAsyncComponent(() => import("./components/AppMain.vue"));

const { proxy } = getCurrentInstance(); 
const route = useRoute();
const router = useRouter();
const current = ref(route.meta.activeMenu);
const showMenu = ref(false);

const address = ref('长沙市芙蓉中路442号新湖南大厦事业楼1201');//公司地址
const businessEmail = ref('liuwei@7pa.com');//合作邮箱
const businessName = ref('刘蔚');//合作联系人

onBeforeRouteUpdate((to) => {
	current.value = to.meta.activeMenu;
	showMenu.value = false;
});

onMounted(()=>{
	getPartDetail({ ifPage: 0, moduleId:50 }).then((res)=>{
		res.rows.map((item) => {
			if(item.title == '合作邮箱'){
				businessEmail.value = proxy.filterHTML(item.textDescription);
			}else if(item.title == '商务联系人'){
				businessName.value = proxy.filterHTML(item.textDescription);
			}else if(item.title == '联系地址'){
				address.value = proxy.filterHTML(item.textDescription);
			}
		})
	})
})

</script>

<style lang="less" scoped>
	.main-container{
		width: 100%;
		&.pc{
			min-width: 1280px;
			.el-main{
				height: calc(100vh - 85px);padding: 0;margin: 0;
			}
			.maxBox{
				max-width: 1200px;margin: 0 auto;
				display: flex;align-items: center;justify-content: space-between;
				.tabBtn{
					display: none;
				}
				.tablist{
					ul{
						display: inline-flex;height: 100%;
						li{
							cursor: pointer;color: #292929;font-size: 16px;
							height: 100%;line-height: 85px;overflow: hidden;
							a{
								display: inline-block;color: #292929;
								padding: 0 40px;
							}
							&:hover{
								a{
									color: #ff5a00;transition: 0.3s;
								}
							}
							&.active{
								position: relative;
								a{
									background: #ff5a00;color: #fff;transition: 0.3s;
								}
								&::after{
									content: '';display: block;
									position: absolute;bottom: -5px;left: calc(50% - 5px);
									width: 10px;height: 10px;
									background: #fff;transform: rotate(132deg);
									transition: 0.3s;
								}
							}
							
						}
						
					}
				}
				.logoImg{
					width: 290px;height: 100%;
					a{
						display: inline-block;font-size: 0;height: 100%;
					}
					img{
						max-width: 100%;
					}
				}
			}
		}
		&.m{
			.el-main{
				height: calc(100vh - 85px);padding: 0;margin: 0;
			}
			.maxBox{
				position: relative;
				.tabBtn{
					position: absolute;top: 30px;right: 20px;
				}
				.tablist{
					position: fixed;z-index: 1111;background: #fff;
					top: 85px;left: 0;width: 100%;height: calc(100vh - 85px);
					ul{
						width: 100%;
						li{
							width: 100%;padding: 20px 0;text-align: center;
							border-bottom: 1px solid #f5f5f5;color: #303030;
							a{
								display: inline-block;color: #292929;
							}
							&.active,&:hover{
								a{
									color: #ff5a00;transition: 0.3s;
								}
							}
						}
					}
				}
				.logoImg{
					width: 220px;height: 100%;
					a{
						display: inline-block;font-size: 0;height: 100%;
					}
					img{
						max-width: 100%;margin-top: 10px;
					}
				}
			}
		}
		.el-header{
			--el-header-padding: 0px;
			.loginBtns{
				--el-border-radius-base: 1px;
			}
			
		}
		.el-footer{
			--el-footer-padding: 0px;
		}
		
		.navbarBox{
			width: 100%;background: #FFFFFF;
			.tabnav{
				width: 100%;height: 85px;
				
				
			}
		}
		.footerBox{
			width: 100%;height: 265px;padding: 20px 0;box-sizing: border-box;
			background: #2d2d2d;color: #d2d2d2;
			.leftLogo{
				height: 100%;padding: 24px 0;box-sizing: border-box;
				font-size: 12px;line-height: 24px;
				a{
					color: #d2d2d2;
				}
				img{
					width: 45px;
					margin-bottom: 0px;
				}
			}
			.qrcodeBox{
				display: flex;align-items: center;justify-content: center;width: 224px;
				.qrcode{
					width: 168px;margin-right: 8px;font-size: 0;
					img{
						width: 168px;height: 168px;
					}
				}
			}
			&.m{
				height: auto;text-align: center;
				.qrcodeBox{
					width: 100%;padding-bottom: 24px;
				}
			}
		}
	}
</style>